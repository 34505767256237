@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat&family=Rajdhani:wght@300&display=swap');


::-webkit-scrollbar{
    width: 0.7rem;
}
::-webkit-scrollbar-track{
    background-color: rgba(15, 16, 21, 1);
}
::-webkit-scrollbar-thumb{
    background-color:#989898 ;
    border-radius: 10vh;
}
::-webkit-scrollbar-thumb:hover{
    background-color:#ffffff ;
    transition: 0.3s ease-in-out;
}


body {
    overflow-x: hidden;
}

.carousel-status {
    display: none;
}

.Home .loaded .content {
    opacity: 0;
  }
  
  .loader-wrapper {
    position: fixed;
    z-index: 9999;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(15, 16, 21, 1);
    display: flex;
    justify-content: center;
    align-items: center;
    transform: scale(1.9); 
    opacity: 1;
  }
  
  .loader {
    border: 4px solid rgba(15, 16, 21, 1);
    border-top: 4px solid #ffb700;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    transition: opacity 0.5s ease-in-out;
    animation: spin 1s linear infinite;
    
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }

.home{
    width: 100%;
}
.homebody{
    /* background-image: linear-gradient(rgba(0, 0, 0, 0.596),rgba(0, 0, 0, 0.55)),url(../../assets/images/alain-pham-P_qvsF7Yodw-unsplash.webp); */

    height: 100vh;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
    width: 100%;
    padding: 150px 5% 5% 5%;
    font-family: Oswald;
    opacity: 1;
}


.mid{
    padding: 20px 20px 20px 0px;
    max-width: 1150px;
    border: solid 3px rgba(255, 255, 255, 0.621);
}
.heading1{
    margin-right:5%;
    padding: 30px;
    font-size: 7em;
    
}

.span1{
   
    -webkit-text-fill-color:transparent;
    -webkit-text-stroke: 1px;
    
}
.subheading{
    padding-left:30px;
    font-weight: 100;
    font-size: 25px;
    
}
.container-fluid .row{
    background-color: rgba(15, 16, 21, 1);
}

.m-v-box{
    border: solid 1px white ;
    margin: 3% 14% 3% 14%;
    padding: 10px;
    background-color: rgba(15, 16, 21, 1);
}

.em-pg{
    margin: 3% 14% 0% 14%;
    padding: 10px;
    background-color: rgba(15, 16, 21, 1);
}

.em-head {
    display: flex;
    justify-content: space-between;
}

.em-pg h1 {
    font-family: 'Montserrat', sans-serif;
}
.m-v-box h1{
    margin-top: -35px;
    margin-left: 10px;
}
.m-v-box div{
    width: 18%;
    height: 100%;
    background-color: rgba(15, 16, 21, 1);
}
.m-v-box p{
    font-size: large;
    padding: 20px 5px 20px 5px;
}

.carousel-part{
    display: flex;
    justify-content: center;
    align-items: center;
}
.carousel-part .carousel{
    height: 50%;
    width: 70%;
    border-radius: 15px;
    margin-bottom: 70px;
}
.carousel .slide .legend {
    background: transparent;
}
.carousel .slider-wrapper{
    aspect-ratio: 16/9;
}
.carousel .thumb{
    aspect-ratio: 16/9;
    border-radius: 5px;
}

.thumbs-wrapper {
    visibility: hidden;
}

.em-pg h5 {
    margin-top: 20px;
    margin-bottom: 20px;
}

.top-decor {
    height: 40px;
    font-family: 'Rajdhani', sans-serif;
    width: 100vw;
    border: 1px solid white;
    border-left: none;
    border-right: none;
    margin-bottom: 70px;
    margin-top: 100px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    font-size: 20px;
    margin-left: 0;
}

.bot-decor {
    height: 40px;
    font-family: 'Rajdhani', sans-serif;
    width: 100vw;
    border: 1px solid white;
    border-left: none;
    border-right: none;
    margin-bottom: 60px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    font-size: 20px;
}

.footer{
    height: 200px;
}
@media only screen and (max-width:700px){
    .heading1{
        font-size: 70px;
    }
    .subheading{
        font-size: medium;
    }
    .m-v-box{
        border: solid 1px white ;
        margin: 8% 8% 8% 8%;
    }
    .m-v-box div{
        width: 50%;
        height: 100%;
        background-color: rgba(15, 16, 21, 1);
    }
    .m-v-box p{
        font-size: small ;
        padding: 10px 3px 10px 3px;
    }
    .m-v-box h1{
        margin-top: -28px;
    }
}

@media only screen and (max-width:600px) {
    .bot-decor, .top-decor {
        font-size: 18px;
    }

    .carousel {
        margin-bottom: 50px;
    }
}

@media only screen and (max-width:460px){
    .heading1{
        font-size: 45px;
        padding: 10px;
    }
    .subheading{
        font-size: medium;
    }
    .subheading{
        margin-top: 20%;
    }
    .heading1{
        margin: 10% 0 10% 0;
    }
    .bot-decor, .top-decor {
        font-size: 15px;
    }
    .em-3 {
        display: none;
    }
}

@media screen and (max-width: 768px){
    .carousel-part .carousel{
        width: 100%;
    }
    .carousel .control-dots{
        bottom: -7px;
    }
    .carousel .slide .legend {
        bottom: 0;
    }
}
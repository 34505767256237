.my-card{
    display:flex; 
    justify-content: center;

}
.cards{
    display:flex; 
    width: 100%;
      position: relative;
    margin:1%;
    max-width:1250px;
    padding:20px;
    justify-content: center;
    align-items: center;
    min-height: 180px;
    border: 2px solid white;
    border-radius: 6px;
}
.content1{
    color: rgb(212, 213, 214);
    font-size: 30px;
  
}

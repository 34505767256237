.line-box{
    position:relative;
    width:50%;
    float:left;
    display: flex;
    align-items: center;
}
.line{
    width: 100%;
    height: 0px;
    border:1px solid white;
}
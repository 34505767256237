.event-tile{
    display: flex;
    justify-content: center;
    height: 530px;
    position: relative;
  }
  .event-pic{
    z-index: 1;
    position: absolute;
    width: 270px;
    height: 270px;
    margin-left: 20px;
  }
  .event-pic img{ 
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius:3px;
    transition: transform 0.3s ease-in-out,box-shadow 0.3s ease-in-out;
  }
  .event-pic img:hover{
    transform: scale(1.04);
    box-shadow: 0 0 30px rgba(10, 30, 101, 0.564)
  }
  .event-name-box{
    display: flex;
    margin-top: 230px;
    margin-left: 17px;
    position: absolute;
    width: 310px;
    height: 210px;
    border: 2px solid #FFFFFF;
    align-items: center;
    justify-content: center;
   
    
  }
  .event-name-box p{
    text-align: center;
    font-size: 20px;
    font-weight: 600;
    margin: 0;
  }
  .event-name-box h3{
    margin : 0;
    font-size: 17px;
    font-weight: 300;
    font-size: smaller;
  }
.body{
  overflow-x: hidden;
  overflow-y: hidden;
}
.awards{
    padding: 35px 0px 0px 0px;
    display: flex;
    flex-direction: row;
}
.line1 {
  margin-top: 50px;
}
.title1 {
  background-image: linear-gradient(
    95.83deg,
    #bf953f 9.39%,
    #ebd197 48.3%,
    rgba(194, 154, 72, 0.953125) 95.27%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  background-repeat: no-repeat;
  font-weight: bold;
}

.heading-title{
    margin: 0px 20px 0px 20px;
    font-family: Oswald;
    font-size: 80px;
}

@media only screen and (max-width: 450px){
    .line1{
        display: none;
    }
    
}
@media only screen and (max-width:650px){
  .heading-title{
    margin-top: 25px;
      font-size: 40px;
  }
}


.sidetitle{
    position: absolute;
    width:330px;
    height: 90px;
    left:85%;
    top:470px;
    font-family: Oswald;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 80px;
    transform: rotate(-90deg);
    letter-spacing:1em;

}
@media only screen and (max-width:986px) {
    .sidetitle{
        display: none;}
    
}
.contact-main{
    padding: 45px 0 2% 0;
   
}
.content-main{
    justify-content: space-evenly;
    
}

.content{
    
    position: relative;
    justify-content: center;
    align-items: center;
    display: flex;
    border: 2px solid rgb(255, 255, 255);
    padding: 70px 30px 30px 30px;
    max-height: 400px;
    max-width: 470px;
}
.map, .cont{
 
    
    max-width: 500px;
    max-height:380px;
}
.bottom{
   margin-left: 2%;
    display: flex;
    flex-direction: row;
    height: 50px;
    width: 400px;
    
    font-size: 25px;
}
.bot{
    margin-left: 10px;
    cursor: pointer;
}
.botline{
    cursor: pointer;
    margin-left: 10px;
    border-left: 3px solid;
    padding-left: 10px;
    color: rgba(245, 245, 245, 0.539);

}
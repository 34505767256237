.footer-main{
    background-color: rgb(11, 11, 14);
    display: flex;
    padding: 3% 0 3% 0;
    
    justify-content: space-evenly;
    overflow-x: hidden  ;
    
   
}
.col{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.footer-heading{
    font-size: 20px;
    width: fit-content;
}
.bottom1{
    
     display: flex;
     flex-direction: row;
     height: 30px;
     width: 300px;
     font-size: 20px;
     justify-content: center;
     
     
     
 }
 .bot1{
     margin-left: 10px;
     cursor: pointer;
 }
.bot1 a {
    color: rgba(255, 255, 255, 0.804);
}

 .botline1{
     cursor: pointer;
     margin-left: 10px;
     border-left: 3px solid;
     padding-left: 10px;
     color: rgba(245, 245, 245, 0.539);
 
 }
 @media only screen and (min-width:990px) {
    .bottom1{
        justify-content: end;
    }
 }
 @media only screen and (max-width:990px) {
    h6{
        font-size:smaller;
    }
    .footer-heading{
        font-size: medium;
    }
}